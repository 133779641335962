import styled from 'styled-components';
import { saasModernTheme } from 'common/theme/saasModern';

export const FooterWrapper = styled.footer`
  position: relative;
  background: #0d0d0d;
  overflow: hidden;
  padding: ${saasModernTheme.space[8]}px;
  box-sizing: content-box;
  @media (max-width: 450px) {
    padding: ${saasModernTheme.space[4]}px;
  }
`;

export const FooterContainer = styled.div`
  display: grid;
  width: 80%;
  margin: 0 auto;
  grid-template-columns: 50% 20% 20%;
  transition: all 0.2s ease;
  @media (max-width: 1540px) {
    grid-template-columns: 50% 25% 25%;
  }
  @media (max-width: 950px) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: ${saasModernTheme.space[8]}px;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  min-width: 240px;
`;

export const ListItem = styled.li`
  min-width: 240px;
  a {
    color: #b6b6b6;
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    text-decoration: none;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: #343d48;
    }
  }
`;

export const TitleInfo = styled.div`
  color: ${saasModernTheme.colors.white};
  font-size: 14px;
  transition: all 0.2s ease;
  text-decoration: none;
  font-weight: bold;
  display: block;
  margin-bottom: ${saasModernTheme.space[5]}px;
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
    color: #343d48;
  }
`;

export const AsideFooter = styled.aside`
  color: ${saasModernTheme.colors.white};
  font-size: 14px;
  transition: all 0.2s ease;
  text-decoration: none;
`;

export const ContentSocialMedia = styled.div`
  margin-top: 64px;
  display: flex;
  gap: 10px;
`;

export const Encript = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #b6b6b6;
`;

export const Box = styled.div``;
